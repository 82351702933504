import navMenuItems from "@/navigation/vertical";
/** รายการเมนูที่ปล่อยผ่านเลย ตรงนี้สำคัญ */

export const menuPermissionPass = [
  "misc-not-authorized",
  "auth-login",
  "setting-profile",
  "my-notifications",
  "inbox",
  "pages-account-setting",
  "auth-forgot-password",
  "dashboard-ecommerce",
  "auth-reset-password",
  "auth-reset-password-success",
]



export const checkMenuFunction = () => {
  // เรียกฟังก์ชั่นจะได้เมนู ก๊อปปี้จาก inspection มาใส่อีกที
  const stage1 = navMenuItems.map((r) => (r.children ? r.children : r));
  const stage2 = [].concat(...stage1);
  const stage3 = stage2.map((r) => (r.children ? r.children : r));
  const stage4 = [].concat(...stage3);
  const stage5 = stage4.map((r) => (r.children ? r.children : r));
  const stage6 = [].concat(...stage5);
  const stage7 = stage6.map((r) => (r.children ? r.children : r));
  const stage8 = [].concat(...stage7);

  return stage8.map((v) => ({
    ...v,
    key: v.route,
    create: false,
    edit: false,
    read: false,
    delete: false,
    all : false
  }));
};

export const menuFunction = (menu) => {
  // เรียกฟังก์ชั่นจะได้เมนู ก๊อปปี้จาก inspection มาใส่อีกที
  const stage1 = menu.map((r) => (r.children ? r.children : r));
  const stage2 = [].concat(...stage1);
  const stage3 = stage2.map((r) => (r.children ? r.children : r));
  const stage4 = [].concat(...stage3);
  const stage5 = stage4.map((r) => (r.children ? r.children : r));
  const stage6 = [].concat(...stage5);
  const stage7 = stage6.map((r) => (r.children ? r.children : r));
  const stage8 = [].concat(...stage7);

  return stage8.map((v) => ({
    ...v,
  }));
};

export const setupMenuFromRoleAppHorizonTal = (permission) => {
  // สำหรับแปลงค่า เมนู ซับซ้อนมาก
  const menu = checkMenuFunction().map((v) => v.route);
  const menuReverse = menu.filter((val) => !permission.includes(val));
  const omitNodeWithName = (tree, name) => {
    if (name.includes(tree.route)) return undefined;

    const children =
      tree.children && tree.children.length > 0
        ? tree.children
            .map((child) => omitNodeWithName(child, name))
            .filter((node) => !!node)
        : undefined;

    return {
      ...tree,
      children,
    };
  };
  const result = navMenuItems.map((v) => omitNodeWithName(v, menuReverse));

  return result;
};

export const setupMenuFromRoleAppVertical = (permission) => {
  // สำหรับแปลงค่า เมนู ซับซ้อนมาก
  const menuFinally = [];
  permission.forEach((element) => {
    if (element.children && element.children.length > 0) {
      menuFinally.push({ header: element.header, icon: element.icon });
      menuFinally.push(...element.children);
    }
  });

  return menuFinally;
};

/** Example :
 * {
    title: "Supplier Registation",
    route: "vendor",
    icon: "FilePlusIcon",
    key: "vendor",
    features: ["read", "approve", "write"], // กำหนดฟังก์ชั่นเพิ่มเติม
  }, */

export const menuDefault = [
  {
    title: "Supplier Registation",
    route: "vendor",
    icon: "FilePlusIcon",
    key: "vendor",
  },
  {
    title: "Customer Registation",
    route: "customer",
    icon: "FilePlusIcon",
    key: "customer",
  },
  {
    title: "Person Purchase Request Table",
    route: "person-purchase-request",
    icon: "",
    key: "person-purchase-request",
  },
  {
    title: "All Purchase Request Table",
    route: "all-purchase-request",
    icon: "",
    key: "all-purchase-request",
  },
  {
    title: "Person Purchase Approval Table",
    route: "person-purchase-approval",
    icon: "",
    key: "person-purchase-approval",
  },
  {
    title: "All Purchase Approval Table",
    route: "all-purchase-approval",
    icon: "",
    key: "all-purchase-approval",
  },
  {
    title: "Person Purchase Order Table",
    route: "person-purchase-order",
    icon: "",
    key: "person-purchase-order",
  },
  {
    title: "All Purchase Order Table",
    route: "all-purchase-order",
    icon: "",
    key: "all-purchase-order",
  },
  {
    title: "Person Purchase Order Approval Table",
    route: "person-purchase-order-approval",
    icon: "",
    key: "person-purchase-order-approval",
  },
  {
    title: "All Purchase Order Approval Table",
    route: "all-purchase-order-approval",
    icon: "",
    key: "all-purchase-order-approval",
  },
  {
    title: "Person Compare Table",
    route: "purchase-compare",
    icon: "",
    key: "purchase-compare",
  },
  {
    title: "All Compare Table",
    route: "all-purchase-compare",
    icon: "",
    key: "all-purchase-compare",
  },
  {
    title: "Summary",
    route: "summary-lists",
    icon: "",
    key: "summary-lists",
  },
  {
    title: "Dashboard",
    icon: "TrendingUpIcon",
    route: "inventory-dashboard",
    key: "inventory-dashboard",
  },
  {
    title: "Dashboard",
    icon: "TrendingUpIcon",
    route: "purchase-dashboard",
    key: "purchase-dashboard",
  },
  {
    title: "Product Master",
    icon: "HardDriveIcon",
    route: "product-master",
    key: "product-master",
  },
  {
    title: "Search Item",
    route: "search",
    icon: "",
    key: "search",
  },
  {
    title: "Person Import Table",
    route: "person-import",
    icon: "",
    key: "person-import",
  },
  {
    title: "All Import Table",
    route: "all-import",
    icon: "",
    key: "all-import",
  },
  {
    title: "Person Import Approval Table",
    route: "person-import-approval",
    icon: "",
    key: "person-import-approval",
  },
  {
    title: "All Import Approval Table",
    route: "all-import-approval",
    icon: "",
    key: "all-import-approval",
  },
  {
    title: "Import By Purchase Order",
    route: "import-by-po",
    icon: "",
    key: "import-by-po",
  },
  {
    title: "Import By Import Request",
    route: "import-by-ir",
    icon: "",
    key: "import-by-ir",
  },
  {
    title: "Person Export Table",
    route: "person-export",
    icon: "",
    key: "person-export",
  },
  {
    title: "All Export Table",
    route: "all-export",
    icon: "",
    key: "all-export",
  },
  {
    title: "Person Export Approval Table",
    route: "person-export-approval",
    icon: "",
    key: "person-export-approval",
  },
  {
    title: "All Export Approval Table",
    route: "all-export-approval",
    icon: "",
    key: "all-export-approval",
  },
  {
    title: "Export POS",
    route: "export-pos",
    icon: "",
    key: "export-pos",
  },
  {
    title: "Return POS",
    route: "return-pos",
    icon: "",
    key: "return-pos",
  },
  {
    title: "Stock Lists",
    route: "stock-lists",
    icon: "",
    key: "stock-lists",
  },
  {
    title: "Person Quotation Table",
    route: "person-quotation",
    icon: "",
    key: "person-quotation",
  },
  {
    title: "All Quotation Table",
    route: "all-quotation",
    icon: "",
    key: "all-quotation",
  },
  {
    title: "Person Quotation Approval Table",
    route: "person-quotation-approval",
    icon: "",
    key: "person-quotation-approval",
  },
  {
    title: "All Quotation Approval Table",
    route: "all-quotation-approval",
    icon: "",
    key: "all-quotation-approval",
  },
  {
    title: "Person Borrow Table",
    route: "person-borrow",
    icon: "",
    key: "person-borrow",
  },
  {
    title: "All Borrow Table",
    route: "all-borrow",
    icon: "",
    key: "all-borrow",
  },
  {
    title: "Person Borrow Approval Table",
    route: "person-borrow-approval",
    icon: "",
    key: "person-borrow-approval",
  },
  {
    title: "All Borrow Approval Table",
    route: "all-borrow-approval",
    icon: "",
    key: "all-borrow-approval",
  },
  {
    title: "Person Ask Table",
    route: "person-table-ask",
    icon: "",
    key: "person-table-ask",
  },
  {
    title: "All Ask Table",
    route: "all-ask",
    icon: "",
    key: "all-ask",
  },
  {
    title: "Person Ask Approval Table",
    route: "person-table-ask-approval",
    icon: "",
    key: "person-table-ask-approval",
  },
  {
    title: "All Ask Approval Table",
    route: "all-ask-approval",
    icon: "",
    key: "all-ask-approval",
  },
  {
    title: "Person Order Table",
    route: "person-order",
    icon: "",
    key: "person-order",
  },
  {
    title: "All Order Table",
    route: "all-order",
    icon: "",
    key: "all-order",
  },
  {
    title: "Person Order Approval Table",
    route: "person-order-approval",
    icon: "",
    key: "person-order-approval",
  },
  {
    title: "All Order Approval Table",
    route: "all-order-approval",
    icon: "",
    key: "all-order-approval",
  },
  {
    title: "Person Invoice Table",
    route: "person-invoice",
    icon: "",
    key: "person-invoice",
  },
  {
    title: "All Invoice Table",
    route: "all-invoice",
    icon: "",
    key: "all-invoice",
  },
  {
    title: "Person Invoice Approval Table",
    route: "person-invoice-approval",
    icon: "",
    key: "person-invoice-approval",
  },
  {
    title: "All Invoice Approval Table",
    route: "all-invoice-approval",
    icon: "",
    key: "all-invoice-approval",
  },
  {
    title: "Cost Table",
    route: "cost-table",
    icon: "",
    key: "cost-table",
  },
  {
    title: "Cost Calendar",
    route: "cost-calendar",
    icon: "",
    key: "cost-calendar",
  },
  {
    title: "Job Table",
    route: "project",
    icon: "",
    key: "project",
  },
  {
    title: "Exchange Rate",
    icon: "DollarSignIcon",
    route: "setting-exchange-rate",
    key: "setting-exchange-rate",
  },
  {
    title: "Generate Code",
    icon: "HashIcon",
    route: "setting-generate-code",
    key: "setting-generate-code",
  },
  {
    title: "User Approve",
    icon: "UserIcon",
    route: "setting-user-approve",
    key: "setting-user-approve",
  },
  {
    title: "Payment",
    icon: "DollarSignIcon",
    route: "setting-payment",
    key: "setting-payment",
  },
  {
    title: "Users Management",
    route: "user-lists",
    icon: "UsersIcon",
    key: "user-lists",
  },
  {
    title: "Role App",
    route: "role",
    icon: "UserIcon",
    key: "role",
  },
  {
    title: "Unit Master Table",
    route: "unit",
    icon: "",
    key: "unit",
  },
  {
    title: "Site Master Table",
    route: "site",
    icon: "",
    key: "site",
  },
  {
    title: "Item Position Master Table",
    route: "item-position",
    icon: "",
    key: "item-position",
  },
  {
    title: "Category List",
    route: "category-lists",
    icon: "",
    key: "category-lists",
  },
];
