import BaseAPIURL from '@core/app-config/baseAPIURL'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // eslint-disable-next-line no-param-reassign
        config.url = BaseAPIURL + config.url
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { response } = error
        // const originalRequest = config

        // if (status === 401) {
        // if (response && response.status === 401) {
        //   if (!this.isAlreadyFetchingAccessToken) {
        //     this.isAlreadyFetchingAccessToken = true
        //     this.refreshToken().then(r => {
        //       this.isAlreadyFetchingAccessToken = false

        //       // Update accessToken in localStorage
        //       this.setToken(r.data.accessToken)
        //       this.setRefreshToken(r.data.refreshToken)

        //       this.onAccessTokenFetched(r.data.accessToken)
        //     })
        //   }
        //   const retryOriginalRequest = new Promise(resolve => {
        //     this.addSubscriber(accessToken => {
        //       // Make sure to assign accessToken according to your response.
        //       // Check: https://pixinvent.ticksy.com/ticket/2413870
        //       // Change Authorization header
        //       originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        //       resolve(this.axiosIns(originalRequest))
        //     })
        //   })
        //   return retryOriginalRequest
        // }
        // Token Expire go to login page
        if (response && response.status === 401) {
          localStorage.clear()
          // Redirect to login page
          router.push({ name: 'auth-login' })
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  forgotpassword(...args) {
    return this.axiosIns.post(this.jwtConfig.forgotpassword, ...args)
  }

  resetpassword(...args) {
    return this.axiosIns.post(this.jwtConfig.resetpassword, ...args)
  }
  
  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  getThemeConfig() {
    return this.axiosIns
      .get('/users/me')
      .then(res => {
        if (res.status === 200 && res.data.themeConfig) {
          return res.data.themeConfig
        }
        return null
      })
      .catch(error => error.response)
  }

  setThemeConfig(args) {
    const usersId = JSON.parse(localStorage.getItem('userData')).id
    return this.axiosIns
      .put(`/users/${usersId}`, { themeConfig: args })
      .catch(error => error.response)
  }

  setUserData(args) {
    const usersId = JSON.parse(localStorage.getItem('userData')).id
    return this.axiosIns
      .put(`/users/${usersId}`, args)
      .catch(error => error.response)
  }

  saveVersions(payload, collection, collectionId , title, status) {
    const users = JSON.parse(localStorage.getItem('userData'))
    
          const payloadVersion = {
            collectionName: collection,
            collectionId,
            title,
            textColor:status,
            createdById: users.id,
            createdByJSON: users,
            createdDateTime: new Date(),
            payload
          }

          return this.axiosIns.post('/versions', payloadVersion)
        
      .catch(error => (error.response ? error.response : error))
  }

  // saveNotification(args) {
  //   const payload = {
  //     ...args,
  //     createdById: JSON.parse(localStorage.getItem('userData')).id,
  //     createdByName: JSON.parse(localStorage.getItem('userData')).username,
  //     createdDateTime: new Date(),
  //   }
  //   return this.axiosIns.post(this.jwtConfig.notificationEndpoint, payload)
  // }

  saveNotification(args, to) {
    const payload = {
      ...args,
      createdById: to.id,
      createdByName: to.username,
      createdDateTime: new Date(),
    }
    return this.axiosIns.post(this.jwtConfig.notificationEndpoint, payload)
  }

  get(resource, slug = '') {
    const slugs = slug ? `/${slug}` : ''
    return this.axiosIns
      .get(`${resource}${slugs}`)
      .catch(error => error.response)
  }

  query(resource, params) {
    return this.axiosIns
      .get(`${resource}?${params ? params : ''}`)
      .catch(error => error.response)
  }
  
  count(resource, params) {
    return this.axiosIns
      .get(`${resource}/${params}`)
      .catch(error => error.response)
  }


  post(resource, params) {
    const payload = {
      ...params,
      createdById: JSON.parse(localStorage.getItem('userData')).id,
      createdByName: JSON.parse(localStorage.getItem('userData')).username,
      createdByJSON: JSON.parse(localStorage.getItem('userData')),
      createdDateTime: new Date(),
    }
    return this.axiosIns
      .post(`${resource}`, payload)
      .catch(error => error.response)
  }

  postByAdmin(resource, params) {
    const payload = {
      ...params,
      // createdById: JSON.parse(localStorage.getItem('userData')).id,
      // createdByName: JSON.parse(localStorage.getItem('userData')).username,
      // createdByJSON: JSON.parse(localStorage.getItem('userData')),
      createdDateTime: new Date(),
    }
    return this.axiosIns
      .post(`${resource}`, payload)
      .catch(error => error.response)
  }

  update(resource, slug, params) {
    const payload = {
      ...params,
      updatedById: JSON.parse(localStorage.getItem('userData')).id,
      updatedByName: JSON.parse(localStorage.getItem('userData')).username,
      updatedByJSON: JSON.parse(localStorage.getItem('userData')),
    }
    return this.axiosIns
      .put(`${resource}/${slug}`, payload)
      .catch(error => error.response)
  }

  put(resource, params) {
    const payload = {
      ...params,
      updatedById: JSON.parse(localStorage.getItem('userData')).id,
      updatedByName: JSON.parse(localStorage.getItem('userData')).username,
      updatedByJSON: JSON.parse(localStorage.getItem('userData')),
    }
    return this.axiosIns
      .put(`${resource}`, payload)
      .catch(error => error.response)
  }

  delete(resource) {
    return this.axiosIns.delete(resource).catch(error => error.response)
  }

  upload(fileData) {
    const formData = new FormData()

    Array.from(fileData).forEach(file => {
      formData.append('files', file)
    })

    return this.axiosIns
      .post('/upload', formData)
      .catch(error => error.response)
  }

  deleteFile(id) {
    return this.axiosIns
      .delete(`/upload/files/${id}`)
      .catch(error => error.response)
  }

  saveLogItem(payload) {
    return this.axiosIns
      .post('/item-logs', payload)
      .catch(error => error.response)
  }

  saveLastCode(payload) {
    return this.axiosIns
      .put('/setting-generate-code', payload)
      .catch(error => error.response)
  }

  sendEmail(payload) {
    return this.axiosIns
      .post('/email', payload)
      .catch(error => error.response)
  }
}
